import React, { Suspense, lazy } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Hadder from "./Components/Navbar/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Loader from "./common/Loader";

const LandingPage = lazy(() => import("./Pages/Landing Page/LandingPage"));
const NotFoundPage = lazy(() => import("./Components/NotFoundPage/NotFoundPage"));
const ContactForm = lazy(() => import("./Pages/Support/Support"));
const TransactionHistory = lazy(() => import("./Pages/Transaction History/TransactionHistory"));

function App() {
  return (
    <>
      <Router>
        <Toaster position="top-right" reverseOrder={false} />
        <Hadder />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route exact index element={<LandingPage />} />
            <Route exact path="/Opportunities" element={<LandingPage />} />
            <Route exact path="/support" element={<ContactForm />} />
            <Route exact path="/trade-history" element={<TransactionHistory />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
