import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BiSupport } from "react-icons/bi";
import { GrTransaction } from "react-icons/gr";
import { PiCurrencyCircleDollarBold } from "react-icons/pi";
import Logo from "../../Assets/Svg/Logo.png";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import Connect from "../ConnectWallet/ConnectWallet";

function Hadder() {
  return (
    <Navbar expand="lg" className="NavbarBody">
      <Container>
        <NavLink to={"/"}>
          <img src={Logo} alt="" width={50} />
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto d-flex align-items-center navbarlinks">
            <NavLink to={"/Opportunities"} activeClassName="active-link">
              {/* <PiCurrencyCircleDollarBold /> */}
              Opportunities
            </NavLink>
            <NavLink to={"/trade-history"} activeClassName="active-link">
              {/* <GrTransaction /> */}
              Trade History
            </NavLink>
            <NavLink to={"/Support"} activeClassName="active-link">
              {/* <BiSupport /> */}
              Support
            </NavLink>
            <Connect/>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Hadder;
